<template>
    <ModelPartForm :model_part="null" :glass_id="glass_id" :readOnly="false" />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'

import ModelPartForm from './ModelPartForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    ModelPartForm
},
  data() {
    return {
      glass_id:this.$route.params.id
    }
  },
  methods:{
    
  },
  async mounted()
  {
   
  }
}
</script>
