
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>

     <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >

      <b-card title="Informations générales">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom du produit"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Render Slug"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Render Slug"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.render_slug"
                :plaintext="readOnly"
                placeholder="Render Slug"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>


          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Layer order"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Layer order"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.layer_order"
                :plaintext="readOnly"
                type="number"
                placeholder="0"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
         
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Type"
              label-for="Type"
            >
           
              <v-select
                v-model="form.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="typeChoices"
                
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Render type"
              label-for="Render type"
            >
            <validation-provider
              #default="{ errors }"
              name="Render type"
              rules="required"
            >
              <v-select
                v-model="form.render_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="renderTypeChoices"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Matière"
              label-for="Matière"
            >
           
              <v-select
                v-model="form.material"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="materialChoices"
               
              />
             
            </b-form-group>
          </b-col>
          
        </b-row>
  
      </b-card>

    <b-card title="Options de personnalisation">
      

        <b-row>
          <b-col
            v-if="form.type == 'color' || form.type == 'texture_color'"
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Couleurs"
              label-for="Couleurs"
            >
            <v-select
              v-model="colorsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="form.material=='metal' ? metalColorChoices : plasticColorChoices"
            >

            <template slot="option" slot-scope="option">
                  <img :src="option.cardImage" width='15' />
                  {{ option.title }}
                </template>

                </v-select>
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
            v-if="form.type == 'texture' || form.type == 'texture_color'"
          >
            <b-form-group
              label="Textures"
              label-for="Textures"
            >
            <v-select
              v-model="texturesSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="textureChoices"
            >

            <template slot="option" slot-scope="option">
              <img :src="option.cardImage" width='15' />
              {{ option.title }}
            </template>
            </v-select>
            
            </b-form-group>
          </b-col>
          <b-col
            v-if="form.render_type == 'lense'"
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Teintes"
              label-for="Teintes"
            >
            <v-select
              v-model="tintsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="tintChoices"
            >
            <template slot="option" slot-scope="option">
                  <img :src="option.cardImage" width='15' />
                  {{ option.title }}
                </template>
                </v-select>
            
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Branches"
              label-for="Branches"
            >
            <v-select
              v-model="branchsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="branchChoices"
            />
            
            </b-form-group>
          </b-col> -->

         

        </b-row>

  
        </b-card>


   </b-form>

  </validation-observer>



  <b-button v-if="edition==false"
     @click.prevent="createModelPart"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click.prevent="updateModelPart"
    >
    Enregistrer
    </b-button>
  </div>

  </b-overlay>
</template>

<script>
import { BButton,BFormInput, BRow, BForm, BCol,BOverlay, BFormGroup,BFormFile, BCard, BCardText, BMedia, BImg, BFormCheckbox, BFormTextarea, BSpinner} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'


export default {
  name: 'ModelPartForm',
  components: {
    BButton,
    BCardText,
    BMedia, 
    BOverlay,
    BImg,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BSpinner,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          loading:true,
          colorsSelected:[],
          texturesSelected:[],
          textureStylesSelected:[],
          tintsSelected:[],
          edition:false,
          loaded:false,
          model_part_id:null,
          glass:null,
          currentBase64:null,
          plastic_colors:[],
          metal_colors:[],
          colors:[],
          tints:[],
          textures:[],
          plasticColorChoices:[],
          metalColorChoices:[],
          colorChoices:[],
          tintChoices:[],
          textureChoices:[],
          renderTypeChoices:['branch','lense','face','manchon','shadow','hinge'],
          typeChoices:['color','texture','texture_color'],
          materialChoices:['plastic','metal'],
          model_part:null,
          initialForm:{
            name:"",
            render_slug:"",
            layer_order:0,
            material:null,
            render_type:null,
            glass:null,
            type:null,
            textures:[],
            tints:[],
            colors:[],
          },
          form:{
            name:"",
            render_slug:"",
            layer_order:0,
            material:null,
            render_type:null,
            glass:null,
            type:null,
            textures:[],
            tints:[],
            colors:[],
          },
          
      }
  },
  computed: {
    
  },
  filters: {
      
  },
  
  watch:{
    
  },
 
  props: [
    'glass_id', 
    'readOnly', 
    'model_part',
   
    ],
  methods:{
   
      async getTextures() {

        console.log('on get les ')
        let textures = await APIRequest.list('textures')
        this.textures=textures[0];
        this.textureChoices=Utils.formatSelectWithImg(this.textures,'name','id','photo')
        if(this.model_part!=null){
          this.formatSelect('textures')
        }
        
      },
     
     
      formatSelect(item) {
        
        if(item=='colors')
        {
          this.colorsSelected=[]
          for(let j=0;j<this.model_part.colors.length;j++)
          {
            for(let i=0;i<this.colorChoices.length;i++)
            {
              if(this.model_part.colors[j].id == this.colorChoices[i].value)
              {
                this.colorsSelected.push(this.colorChoices[i])
              }
            }
          }
        }
        if(item=='textures')
        {
          
          this.texturesSelected=[]
         
          for(let j=0;j<this.model_part.textures.length;j++)
          {
           
            for(let i=0;i<this.textureChoices.length;i++)
            {
            
              if(this.model_part.textures[j].id == this.textureChoices[i].value)
              {
                this.texturesSelected.push(this.textureChoices[i])
                if(!this.textureStylesSelected[j]) {
                  this.textureStylesSelected.push([])
                }
                if(this.model_part.textures[j].texture_styles && this.model_part.textures[j].texture_styles.length>0) {
                  this.textureStylesSelected[j]=this.model_part.textures[j].texture_styles
                }
                else {
                  this.textureStylesSelected[j]=["none"]
                }
              }
         
            }
          }
        }
        if(item=='tints')
        {
          this.tintsSelected=[]
          for(let j=0;j<this.model_part.tints.length;j++)
          {
            for(let i=0;i<this.tintChoices.length;i++)
            {
              if(this.model_part.tints[j].id == this.tintChoices[i].value)
              {
                this.tintsSelected.push(this.tintChoices[i])
              }
            }
          }
        }
        

      },
    
      
      async getTints() {
        let tints = await APIRequest.list('tints')
        this.tints=tints[0];
       
        this.tintChoices=Utils.formatSelectWithImg(this.tints,'name','id','photo')

        if(this.model_part!=null){
          this.formatSelect('tints')
        }

        
      },
      async getColors() {
        
        let colors = await APIRequest.list('colors')
        this.colors=colors[0];
        this.colorChoices=Utils.formatSelectWithImg(this.colors,'name','id','photo')

        if(this.model_part!=null){
          this.formatSelect('colors')
        }
        
      },
      async getMetalColors() {
        let request="?material=metal&limit=1000"
        let colors = await APIRequest.list_with_request('colors',request)
        this.metal_colors=colors[0];
        this.metalColorChoices=Utils.formatSelectWithImg(this.metal_colors,'name','id','photo')

        if(this.model_part!=null){
          this.formatSelect('colors')
        }
        
      },
      async getPlasticColors() {
        let request="?material=plastic&limit=1000"
        let colors = await APIRequest.list_with_request('colors',request)
        this.plastic_colors=colors[0];
        console.log('plastic colooors')
        console.log(colors[0])
        this.plasticColorChoices=Utils.formatSelectWithImg(this.plastic_colors,'name','id','photo')

        if(this.model_part!=null){
          this.formatSelect('colors')
        }
        
      },
      
      async createModelPart()
      {
        this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.loading=true
         
          let datas=this.form
      
          try {
            
              datas.colors=[]
              datas.tints=[]
              datas.textures=[]
              datas.branchs=[]
              datas.glass_types=[]
              datas.glass_options=[]
              datas.refinements=[]
              datas.processings=[]

              for(let j=0;j<this.colorsSelected.length;j++)
              {
                let item={}
                item.id=this.colorsSelected[j].value
                datas.colors.push(item)
              }

              for(let j=0;j<this.tintsSelected.length;j++)
              {
                let item={}
                item.id=this.tintsSelected[j].value
                datas.tints.push(item)
              }
            
              for(let j=0;j<this.texturesSelected.length;j++)
              {
                let item={}
                item.id=this.texturesSelected[j].value
                datas.textures.push(item)
              }
            
            let model_part = await APIRequest.create('model-parts',datas)
            console.log('model part cree')
            console.log(model_part)

            let datas_spec=this.glass

          delete datas_spec['product_variations']
          delete datas_spec['product_content']
          delete datas_spec['model_parts']

          if(datas.render_type=='lense')
          {
            datas_spec.tints=datas.tints
            
            await APIRequest.update('glasses',this.glass_id,datas_spec)
          }


          if(datas.render_type=='face')
          {
            datas_spec.colors=datas.colors
            if(datas.textures.length>0)
            {
              datas_spec.textures=datas.textures
            }
            
            await APIRequest.update('glasses',this.glass_id,datas_spec)
          }

            this.model_part_id=model_part.id
            
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Le model part a bien été créé !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
           this.$router.push({name:'editGlass', params: { id: this.glass_id }})
          } catch (error) {
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
        })
      },
      async updateModelPart()
      {
         this.$refs.simpleRules.validate().then(async success => {
        if (success) {

          
          this.loading=true
          
          var datas=this.form
          
          try {
            datas.colors=[]
            datas.tints=[]
            datas.textures=[]
         

            for(let j=0;j<this.colorsSelected.length;j++)
            {
              let item={}
              item.id=this.colorsSelected[j].value
              datas.colors.push(item)
            }
              
            for(let j=0;j<this.tintsSelected.length;j++)
            {
          
              let item={}
              item.id=this.tintsSelected[j].value
              
              datas.tints.push(item)
              
            }


        
            for(let j=0;j<this.texturesSelected.length;j++)
            {
              let item={}
              item.id=this.texturesSelected[j].value
              datas.textures.push(item)
            }

          


          let model_part = await APIRequest.update('model-parts',this.model_part.id,datas)
          
          let datas_spec=this.glass

          delete datas_spec['product_variations']
          delete datas_spec['product_content']
          delete datas_spec['model_parts']
          if(datas.render_type=='lense')
          {
            datas_spec.tints=datas.tints
            
            await APIRequest.update('glasses',this.glass_id,datas_spec)
          }


          if(datas.render_type=='face')
          {
            datas_spec.colors=datas.colors
            if(datas.textures.length>0)
            {
              datas_spec.textures=datas.textures
            }
            
            await APIRequest.update('glasses',this.glass_id,datas_spec)
          }


          this.model_part_id=model_part.id

          

          
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Le model part a bien été modifié !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name:'editGlass', params: { id: this.glass_id }})
          } catch (error) {
            console.log("error")
            console.log(error)
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
         })
      },
      

      
      
  },
  async mounted(){

    await this.getTextures()
    await this.getTints()
    await this.getColors()
    await this.getPlasticColors()
    await this.getMetalColors()
    
    this.form.glass={id:this.glass_id}

    this.glass=await APIRequest.detail('glasses',this.glass_id)

    if(this.model_part!=null)
    {

     
      this.form=this.model_part
  
      this.edition=true
      
    }

    
    this.loaded=true
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>